<template>
<section class="domain_content">
    <h1>
        Download GB WhatsApp Old Versions Safely
    </h1>

    <p>GB WhatsApp has gained a large user base over the years, with developers consistently releasing new updates packed with additional features and improvements. Each update brings exciting new functionality, yet some users still prefer older versions of the app for various reasons, such as compatibility or personal preference. To cater to this demand, we offer a collection of <a href="https://gbapks.com.ru/" target="_blank">GB WhatsApp</a> APKs from previous versions, organized by year and version number. GBWhatsApp 2023 and 2024 APKs are available for download right here. </p>

    <table>
        <tr>
            <th>App</th>
            <th>GB WhatsApp</th>
        </tr>
        <tr>
            <td>Size</td>
            <td>77M</td>
        </tr>
        <tr>
            <td>Version</td>
            <td>18.20</td>
        </tr>
        <tr>
            <td>Update</td>
            <td>1 day ago</td>
        </tr>
        <tr>
            <td>Site</td>
            <td>{{host}}</td>
        </tr>
    </table>

    <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="download" class="download-img">
        <div class="download-name">
            GB WhatsApp Latest Version
        </div>
    </div>

    <h3>
        GB WhatsApp Old Versions
    </h3>

    <div class="old-version-column">
        <div v-for="(item, index) in versions" :key="index" class="version-item">
            <div class="old-version-row">
                <img src="../assets/shiny-logo.webp" class="head-img" alt="logo" />
                <div class="version-detail">
                    <h4>GB WhatsApp {{ item.version }}</h4>
                    <p><strong>Size:</strong> {{ item.size }}</p>
                    <p><strong>Version:</strong> {{ item.version }}</p>
                    <p><strong>Published:</strong> {{ item.published }}</p>
                    <p v-if="item.notes"><strong>Note:</strong> {{ item.notes }}</p>

                </div>
            </div>
            <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
                <img :src="downloadicon" alt="download" class="download-img">
                <div class="download-name">
                    Download
                </div>
            </div>
        </div>

    </div>

</section>
</template>

<script>
import '@/css/default.scss';
import {
    mapGetters
} from 'vuex';

export default {
    ...{
        "metaInfo": {
            "title": "Download GB WhatsApp Old Versions (2023, 2024) For Free",
            "meta": [{
                "name": "description",
                "content": "This site provides a full list of GBWhatsApp old version APKs. Download GB Whatsapp all versions safely here. "
            }, {
                "name": "title",
                "content": "Download GB WhatsApp Old Versions (2023, 2024) For Free"
            }, {
                "property": "og:title",
                "content": "Download GB WhatsApp Old Versions (2023, 2024) For Free"
            }, {
                "property": "og:description",
                "content": "This site provides a full list of GBWhatsApp old version APKs. Download GB Whatsapp all versions safely here. "
            }]
            ,
            "link": [{
                "rel": "canonical",
                "href": "https://whatsapppro.com/gb-whatsapp-old-versions/"
            }]
        }
    },
    data() {
        return {
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
            versions: [{
                    version: "18.00",
                    size: "75 MB",
                    published: "August 2024",
                    notes: "Improved Anti-Ban Protection & Many Fixes"
                },
                {
                    version: "17.85",
                    size: "70 MB",
                    published: "May 2024",
                    notes: ""
                },
                {
                    version: "17.80",
                    size: "75 MB",
                    published: "May 2024",
                    notes: ""
                },
                {
                    version: "17.76",
                    size: "75 MB",
                    published: "April 2024",
                    notes: "Extending the Expire Date"
                },
                {
                    version: "17.70",
                    size: "75 MB",
                    published: "March 2024",
                    notes: ""
                },
                {
                    version: "17.60",
                    size: "75 MB",
                    published: "December 2023",
                    notes: "Added 25+ New Features"
                },
                {
                    version: "17.55",
                    size: "75 MB",
                    published: "October 2023",
                    notes: ""
                },
                {
                    version: "17.51",
                    size: "75 MB",
                    published: "July 2023",
                    notes: "Fixed App Not Installed As Package Appears To Be Invalid"
                },
                {
                    version: "17.45",
                    size: "75 MB",
                    published: "June 2023",
                    notes: ""
                },
                {
                    version: "17.40",
                    size: "75 MB",
                    published: "May 2023",
                    notes: ""
                }
            ]
        };
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },

        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },

        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
